import React from "react";
import { Image, StyleSheet, View, ImageStyle } from "react-native";
import { Typography, VStack, useTheme, HStack, Link } from "@smartrent/ui";

interface Props {
  title: string;
  message: string;
  imageUri?: string;
  /** Overrides default image style */
  imageStyle?: ImageStyle;
  hideLinks?: boolean;
  hideSidebar?: boolean;
}

export const FullScreenError: React.FC<Props> = ({
  title,
  message,
  imageUri = "/images/404.svg",
  imageStyle,
  hideLinks = true,
}) => {
  const { colors } = useTheme();

  return (
    <View style={styles.root}>
      <Image source={{ uri: imageUri }} style={imageStyle || styles.image} />
      <VStack align="center" spacing={16}>
        <Typography type="title">{title}</Typography>
        <View>
          <Typography style={styles.message}>{message}</Typography>
        </View>
        {hideLinks ? null : (
          <>
            <View>
              <Typography type="title6">
                Here are some helpful links instead:
              </Typography>
            </View>
            <View>
              <HStack spacing={16}>
                <Link href="/">
                  <Typography type="title6" style={{ color: colors.primary }}>
                    Home
                  </Typography>
                </Link>
                <Link href="https://smartrent.com/help">
                  <Typography type="title6" style={{ color: colors.primary }}>
                    Support
                  </Typography>
                </Link>
                <Link href="https://smartrent.com/contact-support#contact">
                  <Typography type="title6" style={{ color: colors.primary }}>
                    Contact
                  </Typography>
                </Link>
              </HStack>
            </View>
          </>
        )}
      </VStack>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  image: {
    margin: 16,
    height: 300,
    width: "100%",
    maxWidth: "100%",
    resizeMode: "contain",
  },
  message: {
    textAlign: "center",
  },
});
