import "vite/modulepreload-polyfill";

import React from "react";
import * as ReactDOMClient from "react-dom/client";

import { BrowserRouter as Router } from "react-router-dom";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { ThemeProvider, ActionSheetProvider, themes } from "@smartrent/ui";
import { QueryClient, QueryClientProvider } from "react-query";

import "./base.css";
import { ErrorBoundary } from "./shared/ErrorBoundary";
import { FullScreenError } from "./shared/FullScreenError";

const queryClient = new QueryClient();

// Mounts the React app to the corresponding DOM element.
// This is the entry point for the all bundles.
// Add any global components here.
export function createBundle(name: string, Bundle: React.FC) {
  const container = document.querySelector(`[data-react-bundle="${name}"]`);

  if (!container) {
    throw new Error(`Could not find container for bundle: ${name}`);
  }

  const root = ReactDOMClient.createRoot(container);

  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={themes.alloy} initialMode="dark">
          <ActionSheetProvider>
            <ErrorBoundary
              fallback={
                <FullScreenError
                  title="Unexpected Error"
                  message="We're sorry but we had trouble loading this page. Please try again."
                />
              }
            >
              <GestureHandlerRootView>
                <Router>
                  <Bundle />
                </Router>
              </GestureHandlerRootView>
            </ErrorBoundary>
          </ActionSheetProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}
